import React from "react";
import "../Style/Contact.css";
import { IoPersonCircleOutline } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";

const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <h2 className="contact-name">Contact Us</h2>
      <div className="container1">
        <div className="contact-info">
          <p className="contact-no">
            <IoCall className="icon-color" /> &nbsp; +91 9890284333
          </p>
          <br />
          <p className="contact-no">
            <IoPersonCircleOutline className="icon-color" /> &nbsp; psuchita085@Gmail.Com
          </p>
          <div className="contact-add">
            <FaLocationDot className="address-icon" />&nbsp;
            <p className="contact-address">
              Flat No. 01, Ankur Society, Manekshanagar, Near Kathe Galli
              Signal, Nashik-Pune Highway, Nashik - 422011
            </p>
          </div>
        </div>
        <div className="contact-form">
          <form>
            <div className="form-group">
              <label htmlFor="name"></label>
              <input
                className="contact-box"
                type="text"
                id="name"
                name="name"
                placeholder="Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email"></label>
              <input
                className="contact-box"
                type="email"
                id="email"
                name="email"
                placeholder="Enter Email Address"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message"></label>
              <textarea
                className="contact-box"
                id="message"
                name="message"
                placeholder="Message"
              ></textarea>
            </div>
            <button type="submit" className="submitbtn">
              Submit
            </button>
          </form>
        </div>
      </div>
      <div className="map-container">
        <iframe
          title="Map"
          width="100%"
          height="400"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.079324905349!2d80.22179951412268!3d12.977063518912792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525f1db39e26dd%3A0x144d76ca3a3227db!2sOpenAI!5e0!3m2!1sen!2sus!4v1644198556143!5m2!1sen!2sus"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
