import React, { useState } from "react";
import "../Style/Footer.css";
import { logo } from "../assets/assetsIndex";
import { MdCall } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { CiMail } from "react-icons/ci";
import { HiOutlinePhone } from "react-icons/hi2";
import { IoLocationSharp } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const emailAddress = " Psuchita085@Gmail.Com";
  const contactNumber = "+91 9890284333";
  const facebook = "https://www.facebook.com/p/Savy-Suchy-100077394136521/"
  const [activeLink, setActiveLink] = useState("aboutus");

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleClick = () => {
    console.log('Button clicked!');
    // Add your button click logic here
  };

  return (
    <div className="footer">
      <div className="footerbox">
        <div className="foot-top">
          <div className="box1">
            <img src={logo} alt="Company Logo" className="foot-logo" />
            <br />
            <span className="company-name">Savy & Suchy</span>
            <br />
            <h3>Contact Info</h3>
            <p>
              <CiMail />{" "}
              <a className="contact" href={`mailto:${emailAddress}`}>
                {" "}
                {emailAddress}
              </a>
            </p>
            <p>
              <HiOutlinePhone />{" "}
              <a className="contact" href={`tel:${contactNumber}`}>
                {contactNumber}
              </a>
            </p>
          </div>
          <div className="box2">
            <h3>Address Info</h3>
            <address className="add">
              <IoLocationSharp />
              Flat No. 01, Ankur Society, Manekshanagar, Near Kathe Galli
              Signal, Nashik-Pune Highway, Nashik
            </address>
          </div>
          <div className="box3">
            <h3>Quick Links</h3>
            <div className="foot-link_box">
              <Link
                className={`nav-item foot-link   ${
                  activeLink === "aboutus" ? "active" : ""
                }`}
                href="/"
                onClick={() => handleNavLinkClick("aboutus")}
              >
                About Us
              </Link>

              <Link
                className={`nav-item foot-link  ${
                  activeLink === "aboutus" ? "active" : ""
                }`}
                href="/about"
                onClick={() => handleNavLinkClick("ourproduct")}
              >
                Our Product
              </Link>

              <Link
                className={`nav-item foot-link  ${
                  activeLink === "aboutus" ? "active" : ""
                }`}
                href="/contact"
                onClick={() => handleNavLinkClick("contactus")}
              >
                Contact Us
              </Link>

              <Link
                className={`nav-item foot-link  ${
                  activeLink === "aboutus" ? "active" : ""
                }`}
                href="/contact"
                onClick={() => handleNavLinkClick("privacypolicy")}
              >
                Privacy Policy
              </Link>

              <Link
                className={`nav-item foot-link ${
                  activeLink === "aboutus" ? "active" : ""
                }`}
                href="/contact"
                onClick={() => handleNavLinkClick("refundplicy")}
              >
                Refund Policy
              </Link>

              <Link
                className={`nav-item foot-link  ${
                  activeLink === "aboutus" ? "active" : ""
                }`}
                href="/contact"
                onClick={() => handleNavLinkClick("terms&condition")}
              >
                Terms & Condition
              </Link>
              <Link
                className={`nav-item foot-link ${
                  activeLink === "aboutus" ? "active" : ""
                }`}
                href="/contact"
                onClick={() => handleNavLinkClick("shippingpolicy")}
              >
                Shipping Policy
              </Link>
            </div>
          </div>
          <div className="box4">
            <h3>Product Categories</h3>
            <p className="box-4">Pickles, Spices, Jam , <br />Chai & Dudh Masala,etc...</p>
            <div className="buttonstyle">
              <a onClick={handleClick}>
                Check
              </a>
            </div>
            <div className="icon-box">
            <a className="icon" href={`faceboook:${facebook}`}><FaFacebookF /></a>
            <a className="icon" href={`tel:${contactNumber}`}><MdCall /></a> 
             <a className="icon3" href={`mailto:${emailAddress}`}><IoIosMail /></a>                         
            </div>
          </div>
        </div>
        <div className="foot-bottom">
          <p>
            © 2023 Savy & Suchy. All Rights Reserved | Design & developed by
            Actify
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
