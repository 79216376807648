import React,{useState} from "react";
import { Link } from "react-router-dom";
import "../Style/Login.css"; 

export default function ForgotPasswordPage() {
    const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div className="modal-overlay">
        <div className="modal">
          <button className="modal-close" onClick={toggleModal}>
            &times;
          </button>
          <h2>Forgot Password</h2>
          <p>Enter your email address below and we'll send you a reset link.</p>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" />
            </div>
            <button type="submit">Send Reset Link</button>
          </form>
          <div className="modal-footer">
            <p>
              Remember your password? <Link to="/login"><a>Log In</a></Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
