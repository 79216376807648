import React from "react";
import "../Style/Gallery.css";
import Cards from "../components/Cards";

const Gallery = () => {
  return (
    <>
      <div className="allProduct_gallery">
        <div className="allProduct_gallery_box">
          <div className="allProduct_gallery_top">
            <h3>Product Categories</h3>
          </div>
          <div className="allProduct_gallery_card-box">
            <Cards />
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
