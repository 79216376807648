import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Style/Profile.css";
import {useSnackbar} from "notistack";
import { baseUrl } from "../utils";


const Profile = () => {
  const [userData, setUserData] = useState({
    id: null,
    supplier_id: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    other_contact_number: "",
    address_line1: "",
    address_line2: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
  });

  const [isDirty, setIsDirty] = useState(false); 
  const [updateVisible, setUpdateVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch user data based on the email stored in localStorage
        const email = localStorage.getItem("email");
        const response = await axios.get(
          `${baseUrl}CustomerDetails/All/Data/${email}`
        );
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    setIsDirty(true); // Set isDirty to true when any input field is changed
    setUpdateVisible(true); // Show update button
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const email = localStorage.getItem("email");
      await axios.post(
        `${baseUrl}CustomerDetails/All/Data/Update/${email}`,
        userData
      );
      enqueueSnackbar(`User data updated successfully!`, {
        variant: "success",
      });
      setUpdateVisible(false); // Hide update button after successful update
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  return (
    <div className="user_profile">
      <h2>Profile</h2>
      <form className="profileForm">
        <div className="profile_sec1">
          <div className="profile_details_inp">
            <label>First Name:</label>
            <input
              type="text"
              name="first_name"
              value={userData.first_name}
              onChange={handleChange}
            />
          </div>
          <div className="profile_details_inp">
            <label>Middle Name:</label>
            <input
              type="text"
              name="middle_name"
              value={userData.middle_name}
              onChange={handleChange}
            />
          </div>
          <div className="profile_details_inp">
            <label>Last Name:</label>
            <input
              type="text"
              name="last_name"
              value={userData.last_name}
              onChange={handleChange}
            />
          </div>
          <div className="profile_details_inp">
            <label>Email:</label>
            <input type="text" name="email" value={userData.email} disabled />
          </div>
          <div className="profile_details_inp">
            <label>Contact Number:</label>
            <input
              type="text"
              name="contact_number"
              value={userData.contact_number}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="profile_sec2">
          <div className="profile_details_inp">
            <label>Address Line 1:</label>
            <input
              type="text"
              name="address_line1"
              value={userData.address_line1}
              onChange={handleChange}
            />
          </div>
          <div className="profile_details_inp">
            <label>Address Line 2:</label>
            <input
              type="text"
              name="address_line2"
              value={userData.address_line2}
              onChange={handleChange}
            />
          </div>
          <div className="profile_details_inp">
            <label>Pincode:</label>
            <input
              type="text"
              name="pincode"
              value={userData.pincode}
              onChange={handleChange}
            />
          </div>
          <div className="profile_details_inp">
            <label>City:</label>
            <input
              type="text"
              name="city"
              value={userData.city}
              onChange={handleChange}
            />
          </div>
          <div className="profile_details_inp">
            <label>State:</label>
            <input
              type="text"
              name="state"
              value={userData.state}
              onChange={handleChange}
            />
          </div>
          <div className="profile_details_inp">
            <label>Country:</label>
            <input
              type="text"
              name="country"
              value={userData.country}
              onChange={handleChange}
            />
          </div>
        </div>
        {updateVisible && (
          <div className="updateProfileBtn">
            <button onClick={handleUpdate}>Update</button>
          </div>
        )}
      </form>
    </div>
  );
};

export default Profile;
