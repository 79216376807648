import React from "react";
import "../Style/Banner.css"; // Change the CSS import name

const Banner = () => {
  return (
    <div className="main-box">
      <div className="banner-box">
        <h1>
          "Get The Natural Spices To Enhance Your Recipes & The Pickles To
          Fulfill Your Meal !"
        </h1>
        <p>Call Us To Order Your Favorite Flavors Of Our Products.</p>
        <h2>+91 9890284333</h2>
      </div>
    </div>
  );
};

export default Banner;
