import React, { useState } from "react";
import "../Style/Login.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Chilli, logo } from "../assets/assetsIndex";
import { baseUrl } from "../utils";
import {useSnackbar} from "notistack"

export function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      console.log("Sending data to backend:", { email, password });
      const response = await axios.post(`${baseUrl}Login/Check`, {
        username: email,
        password: password,
      });

      if (response.data === "Login Successfully") {
        localStorage.setItem("email", email);
        navigate("/");
        enqueueSnackbar("Login Successfully", {
          variant: "success",
        });
      } else if (response.data === "Invalid username") {
        enqueueSnackbar("Invalid username", {
          variant: "error",
        });
        // setError("Invalid username");
      } else if (response.data === "Invalid password") {
        enqueueSnackbar("Invalid password", {
          variant: "error",
        });
        // setError("Invalid password");
      } else {
        enqueueSnackbar("Incorrect username or password. Please enter valid credentials.", {
          variant: "error",
        });
        // setError(
        //   "Incorrect username or password. Please enter valid credentials."
        // );
      }
    } catch (error) {
      console.error("Error occurred:", error);
      enqueueSnackbar("An unexpected error occurred!", {
        variant: "error",
      });
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div className="loginpage">
        <img className="bg-img" src={Chilli} alt="" />
        <div className="login-container">
          <div>
            <Link to="/" className="company_head">
              <img src={logo} alt="Logo" className="comp_logo" />
              <h3>Savy & Suchy</h3>
            </Link>
            <div className="login">
              <h3>Login</h3>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Email Id</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                {error && <p className="error">{error}</p>}
                <button type="submit">Login</button>
              </form>
              <div className="login-footer">
                <p>
                  Not a member?{" "}
                  <Link to="/signup">
                    <a>Sign Up</a>
                  </Link>
                </p>
                <p>
                  Forgot{" "}
                  <Link to="/forgotpassword">
                    <a>Password?</a>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
