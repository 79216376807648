import React from "react";
import Carousel from "../components/Carousel";
import Cards from "../components/Cards";
import { Link } from "react-router-dom";
import "../Style/Home.css";

const Home = () => {
  return (
    <div>
      <Carousel />
      <div className="card-container">
        <p className="p-name">SEE OUR LATEST</p>
        <h3 className="f-name">Featured Product</h3>
        <div className="card-box">
          <Cards />
        </div>
      </div>
      <div className="viewMore_btn">
        <Link to="/gallery">View More</Link>
      </div>
    </div>
  );
};

export default Home;
