import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ProductPage from "./Pages/ProductPage";
import BillingPage from "./Pages/BillingPage";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import OurStory from "./Pages/OurStory";
import Gallery from "./Pages/Gallery";
import ContactUs from "./Pages/ContactUs";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Banner from "./components/Banner";
import LoginPage from "./Pages/LoginPage";
import SignupPage from "./Pages/SignupPage";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage";
import AllCarts from "./Pages/AllCarts";
import Order from "./Pages/Order";
import Profile from "./Pages/Profile";

function App() {
  const Layout = () => {
    return (
      <div className="main">
        <Header />
        <Navbar />
        <div className="contentContainer">
          <Outlet />
        </div>
        <Banner />
        <Footer />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/aboutus",
          element: <AboutUs />,
        },
        {
          path: "/story",
          element: <OurStory />,
        },
        {
          path: "/gallery",
          element: <Gallery />,
        },
        {
          path: "/contact",
          element: <ContactUs />,
        },
        {
          path: "/product/:productId",
          element: <ProductPage />,
        },
        {
          path: "/carts",
          element: <AllCarts />,
        },
        {
          path: "/checkout",
          element: <BillingPage />,
        },
        {
          path: "/myorder",
          element: <Order />,
        },
        {
          path: "/profile",
          element: <Profile />
        }
      ],
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/signup",
      element: <SignupPage />,
    },
    {
      path: "/forgotpassword",
      element: <ForgotPasswordPage />,
    },
  ]);
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
