import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { baseUrl, bolbImgUrl } from "../utils";
import { loader } from "../assets/assetsIndex";
import { FaRupeeSign } from "react-icons/fa";
import { PiShoppingCart } from "react-icons/pi";
import "../Style/ProductPage.css";
import Cards from "../components/Cards";
import { IoBag } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

function ProductPage() {
  const { productId, productTypeIndex } = useParams(); // Get product type index from URL params
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProductType, setSelectedProductType] = useState(null); // State to hold the selected product type
  const [quantity, setQuantity] = useState(1); // State to hold the quantity

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}Product/All/Data/1/${productId}`
        );
        setProduct(response.data);
        // Set the default product type based on the URL params
        if (
          productTypeIndex !== undefined &&
          response.data.producttype[productTypeIndex]
        ) {
          setSelectedProductType(response.data.producttype[productTypeIndex]);
        } else {
          // If no product type is specified in the URL params, use the first product type as default
          setSelectedProductType(response.data.producttype[0]);
        }
      } catch (error) {
        setError(
          error.message || "An error occurred while fetching product data."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId, productTypeIndex]);

  const handleQuantityChange = (event) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value) && value >= 1) {
      setQuantity(value);
    }
  };

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const addToCart = async () => {
    const userEmail = localStorage.getItem("email");
    if (!userEmail) {
      enqueueSnackbar("Please sign in.", {
        variant: "error",
      });
      navigate("/login");
      return;
    }

    if (!selectedProductType) {
      // No product type selected, cannot add to cart
      return;
    }

    const availableQuantity = selectedProductType.stock || 0; // Consider the available quantity from API response

    if (quantity > availableQuantity) {
      enqueueSnackbar(
        `Sorry, the maximum available quantity for this product is ${availableQuantity}.`,
        { variant: "error" }
      );
      return;
    }

    const data = {
      id: selectedProductType.id,
      quantity: quantity,
    };
    console.log(data);

    try {
      const response = await axios.post(
        `${baseUrl}MyCart/Add/Data/${userEmail}`,
        data
      );

      if (response.status === 200) {
        // Product added to cart successfully
        enqueueSnackbar("Product added to cart successfully!", {
          variant: "success",
        });
        console.log("Product added to cart:", data);
      } else {
        // Handle error
        console.error("Failed to add product to cart:", response.statusText);
        enqueueSnackbar(
          "Failed to add product to cart. Please try again later.",
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      // Handle error
      console.error("Error adding product to cart:", error);
      enqueueSnackbar("Network error. Please try again later.", {
        variant: "error",
      });
    }
  };

  const buyNow = async () => {
    const userEmail = localStorage.getItem("email");
    if (!userEmail) {
      enqueueSnackbar("Please sign in.", {
        variant: "error",
      });
      navigate("/login");
      return;
    }

    if (!selectedProductType) {
      // No product type selected, cannot buy now
      return;
    }

    const availableQuantity = selectedProductType.stock || 0; // Consider the available quantity from API response

    if (quantity > availableQuantity) {
      enqueueSnackbar(
        `Sorry, the maximum available quantity for this product is ${availableQuantity}.`,
        { variant: "error" }
      );
      return;
    }

    const data = {
      id: selectedProductType.id,
      quantity: quantity,
    };
    console.log(data);

    try {
      const response = await axios.post(
        `${baseUrl}Quick/Buy/Product/${userEmail}`,
        data
      );

      if (response.status === 200) {
        enqueueSnackbar("Product bought successfully!", {
          variant: "success",
        });
        const responseData = response.data; // Store the response data
        navigate("/checkout", {
          state: { fromBuyNow: true, buyNowData: responseData },
        });
      } else {
        console.error("Error buying product:", response.statusText);
        enqueueSnackbar("Failed to buy product. Please try again later.", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error buying product:", error);
      enqueueSnackbar("Network error. Please try again later.", {
        variant: "error",
      });
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "40vh",
        }}
      >
        <img
          src={loader}
          width={150}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          alt="Loading....."
        />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <div className="product">
        <div className="product-content">
          <div className="productPage_left-section">
            {selectedProductType && (
              <img
                src={`${bolbImgUrl}${selectedProductType.image}`}
                alt={selectedProductType.product_type_details}
                className="product-image"
              />
            )}
          </div>
          <div className="productPage_right-section">
            <div className="product-header">
              {selectedProductType.stock === "Stock Out" ? (
                <>
                  <p className="stock" style={{ background: "#ff343e" }}>
                    Out Stock
                  </p>
                  <p className="card_productstock">0</p>
                </>
              ) : (
                <>
                  <p className="stock">In Stock</p>
                  <p className="card_productstock">
                    {selectedProductType.stock}
                  </p>
                </>
              )}
            </div>
            <h2>{product.name}</h2>
            <div className="product_types">
              {product.producttype.map((productType, index) => (
                <div key={productType.id} className="product_type">
                  <button
                    onClick={() => setSelectedProductType(productType)}
                    className={`product_type_button ${
                      selectedProductType === productType ? "selected" : ""
                    }`}
                  >
                    {productType.product_type_details}
                  </button>
                </div>
              ))}
            </div>
            {selectedProductType && (
              <>
                <p className="product_price">
                  <FaRupeeSign />
                  <span>{selectedProductType.price}</span>
                </p>
                <p>
                  <b>Rating:</b> {product.rating}
                </p>
                <p className="product_desc">
                  <p>{product.description}</p>
                </p>

                <p>
                  <b>Grams:</b> {selectedProductType.product_type_details}
                </p>
                <div className="product_quantity_addCart">
                  <div className="inc_dec" style={{ width: "fit-content" }}>
                    <button className="inc" onClick={decrementQuantity}>
                      -
                    </button>
                    <span>{quantity}</span>
                    <button className="dec" onClick={incrementQuantity}>
                      +
                    </button>
                  </div>
                  {selectedProductType.stock === "Stock Out" ? (
                    <button className="buyBtn" disabled>
                      Stock Out
                    </button>
                  ) : (
                    <div className="buyNow_addToCartBtn">
                      <button className="buyBtn" onClick={buyNow}>
                        Buy Now <IoBag />
                      </button>
                      <button
                        className="product_add-to-cart-btn"
                        onClick={addToCart}
                      >
                        Add to Cart <PiShoppingCart />
                      </button>
                    </div>
                  )}
                </div>
                <p className="product_category">
                  <b>CATEGORY</b>: <span>{product.tags}</span>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="card-container">
        <p className="p-name">SEE OUR Others</p>
        <h3 className="f-name">Featured Product</h3>
        <div className="card-box">
          <Cards />
        </div>
      </div>
    </>
  );
}

export default ProductPage;
