import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Style/SignUp.css";
import axios from "axios";
import { logo, signupimg } from "../assets/assetsIndex";
import { baseUrl } from "../utils";

export function SignupPage() {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      console.log("Sending data to backend:", {
        firstname,
        lastname,
        email,
        password,
        phone,
      });
      const response = await axios.post(`${baseUrl}Customer/Registration/1`, {
        first_name: firstname,
        last_name: lastname,
        email_id: email,
        password: password,
        mobile_number: phone,
      });

      // If registration is successful, navigate to login page
      if (response.status === 200) {
        navigate("/login");
      }
    } catch (error) {
      // Handle errors here
      setError(error.message);
    }
  };

  return (
    <>
      <div className="SignUppage">
        <img className="bg-img" src={signupimg} alt="" />
        <div className="SignUp-container">
          <div>
            <Link to="/" className="company_head">
              <img src={logo} alt="Logo" className="comp_logo" />
              <h3>Savy & Suchy</h3>
            </Link>
            <div className="SignUp">
              <h3>Sign Up</h3>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <label htmlFor="firstname">First Name</label>
                  <input
                    type="text"
                    id="firstname"
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastname">Last Name</label>
                  <input
                    type="text"
                    id="lastname"
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone No.</label>
                  <input
                    type="text"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
                {error && <p className="error">{error}</p>}
                <button type="submit">Sign Up</button>
              </form>
              <div className="SignUp-footer">
                <p>
                  Already a member?{" "}
                  <Link to="/login">
                    <a>Log In</a>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupPage;
