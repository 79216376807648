import React, { useState, useEffect, useRef } from "react";
import "../components/Navbar";
import "../Style/Navbar.css";
import { loader, logo } from "../assets/assetsIndex";
import { PiShoppingCart } from "react-icons/pi";
import { IoBagCheckOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";
import { MdCancel, MdOutlineMenu } from "react-icons/md";
import { MdClose } from "react-icons/md";
import axios from "axios";
import { bolbImgUrl, baseUrl } from "../utils";
import { useSnackbar } from "notistack";
import "../Style/ShopNow.css";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Check if email is stored in localStorage
    const userEmail = localStorage.getItem("email");
    setIsLoggedIn(!!userEmail); // Update isLoggedIn based on email presence

    // Fetch cart data if user is logged in
    if (userEmail) {
      fetchCartData(userEmail);
      fetchFirstName(userEmail);
    }

    // Fetch cart data every second
    const interval = setInterval(() => {
      if (userEmail) {
        fetchCartData(userEmail);
      }
    }, 1000);

    // Clean up the interval on unmount
    return () => clearInterval(interval);
  }, []);

  const fetchCartData = (email) => {
    // Replace email in the API URL with localStorage email
    const apiUrl = `${baseUrl}MyCart/All/Data/${email}`;

    axios
      .get(apiUrl)
      .then((response) => {
        // Handle successful response
        setCartItems(response.data); // Assuming response.data is an array of cart items
        setLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching cart data:", error);
      });
  };

  const fetchFirstName = (email) => {
    const apiUrl = `${baseUrl}CustomerDetails/All/Data/${email}`;

    axios
      .get(apiUrl)
      .then((response) => {
        // Handle successful response
        const { first_name } = response.data;
        setFirstName(first_name.charAt(0)); // Get the first letter of first_name
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching user data:", error);
      });
  };

  const deleteItem = async (itemId) => {
    try {
      await axios.post(
        `${baseUrl}MyCart/Delete/Data/${localStorage.getItem(
          "email"
        )}`,
        { id: itemId }
      );
      fetchCartData(); // Refetch data after successful deletion
      setLoading(false);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const activeLink = (pathname) => {
    return location.pathname === pathname;
  };

  // Function to handle logout
  const handleLogout = () => {
    // Perform logout logic
    // For example, clear session, remove tokens, etc.
    sessionStorage.clear();
    localStorage.removeItem("email");
    navigate("/login");
    enqueueSnackbar("Logout Successfully.", {
      variant: "success",
    });

    // Then update isLoggedIn state to false
    setIsLoggedIn(false);
  };

  // Function to open modal
  const openCartModal = () => {
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeCartModal = () => {
    setIsModalOpen(false);
  };

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeCartModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <nav className={openMenu ? "navbar mobileActive" : "navbar"}>
      <div className="navbar-brand">
        <div className="nav-left">
          <img src={logo} alt="Company Logo" className="company-logo" />
          <span className="company-name">Savy & Suchy</span>
        </div>
        <div className="nav-mid">
          <Link
            className={`nav-item ${activeLink("/") && "nav_active"}`}
            to="/"
            onClick={() => setOpenMenu(false)}
          >
            Home
          </Link>

          <Link
            className={`nav-item ${activeLink("/gallery") && "nav_active"}`}
            to="/gallery"
            onClick={() => setOpenMenu(false)}
          >
            Gallery
          </Link>

          <Link
            className={`nav-item ${activeLink("/aboutus") && "nav_active"}`}
            to="/aboutus"
            onClick={() => setOpenMenu(false)}
          >
            About Us
          </Link>

          <Link
            className={`nav-item ${activeLink("/story") && "nav_active"}`}
            to="/story"
            onClick={() => setOpenMenu(false)}
          >
            Our Story
          </Link>

          <Link
            className={`nav-item ${activeLink("/contact") && "nav_active"}`}
            to="/contact"
            onClick={() => setOpenMenu(false)}
          >
            Contact Us
          </Link>
          <Link
            className={`nav-item ${activeLink("/myorder") && "nav_active"}`}
            to="/myorder"
            onClick={() => setOpenMenu(false)}
          >
            My Order
          </Link>
        </div>
        <div className="nav-right">
          <div className="header-right">
            {isLoggedIn && (
              <Link to="/profile" className="profileIcon">
                {firstName}
              </Link>
            )}
            {/* Conditional rendering of login/logout button */}
            {isLoggedIn ? (
              <button onClick={handleLogout} className="shop-button">
                Sign Out
              </button>
            ) : (
              <Link to="/login" className="shop-button">
                Sign In
              </Link>
            )}
            <button onClick={openCartModal} className="nav_cart_btn">
              <div className="nav_cartItems_count">
                <PiShoppingCart className="shoppingCartIcon" />
                <span>{cartItems.length}</span> {/* Display number of items */}
              </div>
              <p>
                <FaRupeeSign />
                {/* Calculate total price */}
                {cartItems.reduce((total, item) => total + item.price, 0)}
              </p>
            </button>
          </div>
          <div className="menuClose_icon">
            <MdOutlineMenu
              className="mobile_nav_icon menuIcon"
              onClick={() => setOpenMenu(true)}
            />
            <MdClose
              className="mobile_nav_icon closeIcon"
              onClick={() => setOpenMenu(false)}
            />
          </div>
        </div>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className="cartmodal">
          <div className="cartmodal_box" ref={modalRef}>
            <div className="cartmodal-content">
              <div className="cartmodal-body">
                <div className="cartmodal_head">
                  <p>Shopping Cart</p>
                  <span className="cart-close" onClick={closeCartModal}>
                    &times;
                  </span>
                </div>
                {cartItems.length === 0 ? (
                  <div className="shopnow">
                    <p>No products added</p>
                    <Link
                      onClick={() => {
                        navigate("/");
                        closeCartModal();
                      }}
                    >
                      Shop Now
                    </Link>
                  </div>
                ) : loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40vh",
                    }}
                  >
                    <img
                      src={loader}
                      width={150}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      alt="Loading....."
                    />
                  </div>
                ) : (
                  <div className="cartmodal_mid">
                    {cartItems.map((item) => (
                      <div key={item.id} className="cartmodalItem">
                        <MdCancel
                          className="cartproduct_delete"
                          onClick={() => deleteItem(item.id)}
                        />
                        <Link
                          className="cartproduct_link"
                          to={`/product/${item.product.id}`}
                          onClick={closeCartModal}
                        >
                          <img
                            src={`${bolbImgUrl}${item.producttype.image}`}
                            alt={item.product.name}
                            className="cartproduct_img"
                          />
                          <div className="cartproduct_details">
                            <p className="cartproduct_details_name">
                              {item.product.name} -
                              <span className="gram">
                                {item.producttype.product_type_details}
                              </span>
                            </p>
                            <p>
                              {item.quantity}x
                              <span className="cartproduct_details_price">
                                <FaRupeeSign />
                                {item.price}
                              </span>
                            </p>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                )}

                <div className="cartmodal_bottom">
                  <div className="cartmodal_total">
                    <p>SUBTOTAL:</p>
                    <p>
                      <FaRupeeSign />
                      {/* Calculate total price */}
                      {cartItems.reduce((total, item) => total + item.price, 0)}
                    </p>
                  </div>
                  <Link
                    to="/checkout"
                    className="cartmodal_checkout"
                    onClick={closeCartModal}
                  >
                    <IoBagCheckOutline />
                    CheckOut
                  </Link>
                  <Link
                    to="/carts"
                    className="cartmodal_viewChart"
                    onClick={closeCartModal}
                  >
                    <PiShoppingCart />
                    View Cart
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
