import React, { useState } from "react";
import { FaRupeeSign } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { baseUrl, bolbImgUrl } from "../utils";
import "../Style/Modal.css";
import { PiShoppingCart } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const Modal = ({ product, closeModal }) => {
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddToCart = () => {
    // Check if user email is stored in localStorage
    const userEmail = localStorage.getItem("email");
    if (!userEmail) {
      navigate("/login");
      alert("Please sign in first to add items to cart.");
      return;
    }

    // Prepare the data object to be sent to the backend
    const data = {
      id: product.id, // Assuming the product object has an 'id' property
      quantity: quantity,
    };

    // Replace the email in the URL with the user's email from localStorage
    const apiUrl = `${baseUrl}MyCart/Add/Data/${userEmail}`;
    console.log(userEmail);
    console.log(data);

    // Make a POST request to the backend API
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          // If the request is successful, display a success message
          alert("Product added to cart successfully!");
          // You can also perform additional actions here, such as closing the modal
          closeModal();
        } else {
          // If there's an error in the request, you can handle it here
          console.error("Error adding product to cart:", response.statusText);
          // Display an error message to the user
          alert("Failed to add product to cart. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error adding product to cart:", error);
        // Handle any network errors here
        // Display an error message to the user
        alert("Network error. Please try again later.");
      });
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">
          <div className="left-section">
            <img
              src={`${bolbImgUrl}${product.image}`}
              alt={product.name}
              className="modal-image"
            />
          </div>
          <div className="right-section">
            <div className="modal-header">
              <p className="stock">In Stock</p>
              <button className="close-modal-btn" onClick={closeModal}>
                <FaTimes />
              </button>
            </div>
            <h2>{product.name}</h2>
            <p className="product_price">
              <FaRupeeSign />
              <span>{product.price}</span>
            </p>
            <p>
              <b>Rating:</b> {product.rating}
            </p>
            <div className="productWeights">
              {product.producttype.map((type) => (
                <button key={type.id}>{type.product_type_details}</button>
              ))}
            </div>
            <p className="product_desc">
              <b>Ingredients:</b>
              <p>
                Aamba, Suddha Mith, Shengdana tel, Mirchi powder, Mohari, Methi,
                Halad, Hing Store in a cool, dry & hygienic place, Away from
                direct sunlight.
              </p>
            </p>

            <div className="modal-footer">
              <div className="quantity-controls">
                <button onClick={decrementQuantity}>-</button>
                <span className="product_quantity">{quantity}</span>
                <button onClick={incrementQuantity}>+</button>
              </div>
              <button className="add-to-cart-btn" onClick={handleAddToCart}>
                <PiShoppingCart /> Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
