import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../Style/Order.css";
import "../Style/ShopNow.css";
import { useSnackbar } from "notistack";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { baseUrl } from "../utils";

const Order = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [requestedOrders, setRequestedOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [orderTab, setOrderTab] = useState(1);
  const userEmail = localStorage.getItem("email");

  const fetchRequestedOrders = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}YourOrder/Requested/All/Data/${userEmail}`
      );
      const data = response.data;
      setRequestedOrders(data);
    } catch (error) {
      console.error("Error fetching requested orders:", error);
    }
  };

  const fetchCompletedOrders = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}YourOrder/Completed/All/Data/${userEmail}`
      );
      const data = response.data;
      setCompletedOrders(data);
    } catch (error) {
      console.error("Error fetching confirmed orders:", error);
    }
  };

  const fetchCancelledOrders = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}YourOrder/Cancelled/All/Data/${userEmail}`
      );
      const data = response.data;
      setCancelledOrders(data);
    } catch (error) {
      console.error("Error fetching cancelled orders:", error);
    }
  };

  const fetchOrderHistory = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}YourOrder/All/Data/${userEmail}`
      );
      const data = response.data;
      setOrderHistory(data);
    } catch (error) {
      console.error("Error fetching order history:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchRequestedOrders(),
        fetchCompletedOrders(),
        fetchCancelledOrders(),
        fetchOrderHistory(),
      ]);
    };

    // Fetch data initially
    fetchData();

    // Set up interval to fetch data every second
    const intervalId = setInterval(fetchData, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  const cancelOrder = async (orderId) => {
    try {
      await axios.post(
        `${baseUrl}Your/Order/Cancelled/${userEmail}/${orderId}`
      );
      enqueueSnackbar("The order has been successfully canceled!", {
        variant: "success",
      });
      console.log("Order canceled successfully");
      // fetchRequestedOrders()
    } catch (error) {
      console.error("Error canceling order:", error);
    }
  };

  const getOrderStatusColor = (status) => {
    switch (status) {
      case "Cancelled":
        return "#ffbbbb";
      case "Order Placed":
        return "#c2edff";
      case "Delivered":
        return "#90ff90";
      default:
        return "white";
    }
  };

  function orderToggle(id) {
    setOrderTab(id);
  }

  const handleInvoice = async (orderId) => {
    try {
      const response = await axios.get(
        `${baseUrl}Invoice/Order/Details/${orderId}`
      );

      // Create a new jsPDF instance
      const doc = new jsPDF();

      // Add content to the PDF

      doc.text("Invoice Details", 10, 10);
      doc.text(`Order Id: ${response.data.id}`, 10, 20);
      doc.text(
        `Name: ${response.data.first_name} ${response.data.last_name}`,
        10,
        30
      );
      // Add email and address
      doc.text(
        `Email: ${response.data.email}`,
        10,
        40
      );
      doc.text(
        `Address: ${response.data.address1}, ${response.data.address2}`,
        10, 50
      );

      doc.setFontSize(10);
      doc.setTextColor("#666");

      // Table headers
      const headers = ["Product", "Gram", "Price", "Quantity"];
      const productsData = response.data.orderproduct.map((product) => [
        product.name,
        product.product_type_details,
        product.price,
        product.quantity,
      ]);

      // Create table
      doc.autoTable({
        startY: 60,
        head: [headers],
        body: productsData,
        theme: "striped",
        styles: { fontSize: 10 },
        columnStyles: { 3: { cellWidth: "auto" } },
      });

      // Add total price and cart status
      const totalPrice = response.data.total_price;
      const cartStatus = response.data.order_status;
      
      doc.text(
        `Total Price: ${totalPrice}`,
        10,
        doc.autoTable.previous.finalY + 10
      );
      
      doc.text(
        `Cart Status: ${cartStatus}`,
        10,
        doc.autoTable.previous.finalY + 20
      );
      
      
      // Save the PDF document
      doc.save("invoice.pdf");
    } catch (error) {
      console.error("Error fetching invoice details:", error);
    }
  };

  return (
    <div className="orderpage">
      <h2>Order Details</h2>
      <div className="all_order_details">
        <div className="tabs_button_box">
          <button
            className={orderTab === 1 ? "activetab_btn" : "tabsButton"}
            onClick={() => orderToggle(1)}
          >
            Requested
          </button>
          <button
            className={orderTab === 2 ? "activetab_btn" : "tabsButton"}
            onClick={() => orderToggle(2)}
          >
            Delivered
          </button>
          <button
            className={orderTab === 3 ? "activetab_btn" : "tabsButton"}
            onClick={() => orderToggle(3)}
          >
            Cancelled
          </button>
          <button
            className={orderTab === 4 ? "activetab_btn" : "tabsButton"}
            onClick={() => orderToggle(4)}
          >
            Order History
          </button>
        </div>
        <div className="order_boxes">
          <div className={orderTab === 1 ? "order_box" : "tabs_content"}>
            {requestedOrders.length > 0 ? (
              requestedOrders.map((order, index) => (
                <div className="order_item" key={index}>
                  <div className="order_top">
                    <h4>
                      <b>
                        Order Id: <span>{order.id}</span>
                      </b>
                    </h4>
                    <div className="order_status">
                      <p
                        style={{
                          background: getOrderStatusColor(order.order_status),
                        }}
                      >
                        {order.order_status}
                      </p>
                      {["Order Placed", "Shipped", "Packed"].includes(
                        order.order_status
                      ) && (
                        <button
                          className="invoice_btn"
                          onClick={() => handleInvoice(order.id)}
                        >
                          Invoice
                        </button>
                      )}
                      {order.order_status !== "Shipped" && (
                        <button
                          className="cancel_order_btn"
                          onClick={() => cancelOrder(order.id)}
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                  <p>
                    {order.first_name} {order.last_name}
                  </p>
                  <p>{order.email}</p>
                  <p>{order.phone}</p>
                  <p>
                    <b>Address: </b>
                    {order.address1}, {order.address2}
                  </p>
                  <p>
                    <b>Payment Mode: </b>
                    {order.payment_mode}
                  </p>
                </div>
              ))
            ) : (
              <div className="shopnow">
                <p>No order found.</p>
                <Link
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Shop Now
                </Link>
              </div>
            )}
          </div>
          <div className={orderTab === 2 ? "order_box" : "tabs_content"}>
            {completedOrders.length > 0 ? (
              completedOrders.map((order, index) => (
                <div className="order_item" key={index}>
                  <div className="order_top">
                    <h4>
                      <b>
                        Order Id: <span>{order.id}</span>
                      </b>
                    </h4>
                    <div className="order_status">
                      <p
                        style={{
                          background: getOrderStatusColor(order.order_status),
                        }}
                      >
                        {order.order_status}
                      </p>
                      {order.order_status !== "Delivered" && (
                        <button
                          className="cancel_order_btn"
                          onClick={() => cancelOrder(order.id)}
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                  <p>
                    {order.first_name} {order.last_name}
                  </p>
                  <p>{order.email}</p>
                  <p>{order.phone}</p>
                  <p>
                    <b>Address: </b>
                    {order.address1}, {order.address2}
                  </p>
                  <p>
                    <b>Payment Mode: </b>
                    {order.payment_mode}
                  </p>
                </div>
              ))
            ) : (
              <div className="shopnow">
                <p>No order found.</p>
                <Link
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Shop Now
                </Link>
              </div>
            )}
          </div>
          <div className={orderTab === 3 ? "order_box" : "tabs_content"}>
            {cancelledOrders.length > 0 ? (
              cancelledOrders.map((order, index) => (
                <div className="order_item" key={index}>
                  <div className="order_top">
                    <h4>
                      <b>
                        Order Id: <span>{order.id}</span>
                      </b>
                    </h4>
                    <div className="order_status">
                      <p
                        style={{
                          background: getOrderStatusColor(order.order_status),
                        }}
                      >
                        {order.order_status}
                      </p>
                      {order.order_status !== "Cancelled" &&
                        order.order_status !== "Failed" &&
                        order.order_status !== "Rejected" && (
                          <button
                            className="cancel_order_btn"
                            onClick={() => cancelOrder(order.id)}
                          >
                            Cancel
                          </button>
                        )}
                    </div>
                  </div>
                  <p>
                    {order.first_name} {order.last_name}
                  </p>
                  <p>{order.email}</p>
                  <p>{order.phone}</p>
                  <p>
                    <b>Address: </b>
                    {order.address1}, {order.address2}
                  </p>
                  <p>
                    <b>Payment Mode: </b>
                    {order.payment_mode}
                  </p>
                </div>
              ))
            ) : (
              <div className="shopnow">
                <p>No order found.</p>
                <Link
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Shop Now
                </Link>
              </div>
            )}
          </div>
          <div className={orderTab === 4 ? "order_box" : "tabs_content"}>
            {orderHistory.length > 0 ? (
              orderHistory.map((order, index) => (
                <div className="order_item" key={index}>
                  <div className="order_top">
                    <h4>
                      <b>
                        Order Id: <span>{order.id}</span>
                      </b>
                    </h4>
                    <div className="order_status">
                      <p
                        style={{
                          background: getOrderStatusColor(order.order_status),
                        }}
                      >
                        {order.order_status}
                      </p>
                      {order.order_status !== "Cancelled" &&
                        order.order_status !== "Failed" &&
                        order.order_status !== "Shipped" &&
                        order.order_status !== "Delivered" &&
                        order.order_status !== "Rejected" && (
                          <button
                            className="cancel_order_btn"
                            onClick={() => cancelOrder(order.id)}
                          >
                            Cancel
                          </button>
                        )}
                    </div>
                  </div>
                  <p>
                    {order.first_name} {order.last_name}
                  </p>
                  <p>{order.email}</p>
                  <p>{order.phone}</p>
                  <p>
                    <b>Address: </b>
                    {order.address1}, {order.address2}
                  </p>
                  <p>
                    <b>Payment Mode: </b>
                    {order.payment_mode}
                  </p>
                </div>
              ))
            ) : (
              <div className="shopnow">
                <p>No order found.</p>
                <Link
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Shop Now
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
