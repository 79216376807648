// BillingPage.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaRupeeSign } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate, useLocation } from "react-router-dom";
import "../Style/Billing.css";
import { useSnackbar } from "notistack";
import { baseUrl } from "../utils";

export function BillingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { fromBuyNow, buyNowData } = location.state || {};

  const [contactDetails, setContactDetails] = useState({
    firstname: "",
    lastname: "",
    phone: "",
  });

  const [addressDetails, setAddressDetails] = useState({
    pincode: "",
    address: "",
    city: "",
    state: "",
  });

  const [cartItems, setCartItems] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userEmail = localStorage.getItem("email");
        if (!userEmail) {
          enqueueSnackbar("Please sign in.", {
            variant: "error",
          });
          navigate("/login");
          return;
        }

        const userResponse = await axios.get(
          `${baseUrl}CustomerDetails/All/Data/${userEmail}`
        );

        const userData = userResponse.data;
        const { first_name, last_name, contact_number } = userData;

        setContactDetails({
          firstname: first_name,
          lastname: last_name,
          phone: contact_number,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [navigate, enqueueSnackbar]);

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const userEmail = localStorage.getItem("email");
        if (!userEmail) {
          enqueueSnackbar("Please sign in.", {
            variant: "error",
          });
          navigate("/login");
          return;
        }

        let cartData = [];

        if (fromBuyNow && buyNowData) {
          cartData = buyNowData;
        } else {
          const cartResponse = await axios.get(
            `${baseUrl}MyCart/All/Data/${userEmail}`
          );
          cartData = cartResponse.data;
        }

        setCartItems(cartData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCartData();
  }, [navigate, fromBuyNow, buyNowData, enqueueSnackbar]);

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactDetails({ ...contactDetails, [name]: value });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddressDetails({ ...addressDetails, [name]: value });
  };

  const handlePincodeChange = async (e) => {
    const pincode = e.target.value;
    setAddressDetails((prevAddressDetails) => ({
      ...prevAddressDetails,
      pincode: pincode,
    }));
    if (pincode.length === 6) {
      try {
        const response = await fetch(
          `https://api.postalpincode.in/pincode/${pincode}`
        );
        const data = await response.json();
        if (
          data &&
          data[0] &&
          data[0].PostOffice &&
          data[0].PostOffice.length > 0
        ) {
          const { State, District } = data[0].PostOffice[0];
          setAddressDetails((prevAddressDetails) => ({
            ...prevAddressDetails,
            city: District,
            state: State,
          }));
        }
      } catch (error) {
        console.error("Error fetching city and state:", error);
      }
    }
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (paymentMethod === "online") {
      try {
        const userEmail = localStorage.getItem("email");
        if (!userEmail) {
          enqueueSnackbar("Please sign in.", {
            variant: "error",
          });
          navigate("/login");
          return;
        }

        // Prepare the form data including all input field values
        const formData = {
          first_name: contactDetails.firstname,
          last_name: contactDetails.lastname,
          mobile_number: contactDetails.phone,
          address: addressDetails.address,
          pincode: addressDetails.pincode,
          city: addressDetails.city,
          state: addressDetails.state,
          payment_mode: "Online Payment",
          cart_status: fromBuyNow ? "True" : "False",
        };

        const totalAmountInPaise =
          cartItems.reduce((acc, item) => acc + item.price, 0) * 100;

        console.log("Total amount:", totalAmountInPaise);

        // Make a request to save the order with all the form data
        const response = await axios.post(
          `${baseUrl}MyCart/Checkout/Save/${
            userEmail || localStorage.getItem("email")
          }`,
          formData
        );

        const { data } = response;
        console.log(data);

        const options = {
          key: "rzp_test_YpA0PusgX6aQWv",
          amount: totalAmountInPaise,
          currency: "INR",
          name: "Savy & Suchy",
          description: "Payment for Products",
          handler: function (response) {
            console.log("Payment successful!", response);
            enqueueSnackbar(`Transaction Successful!`, {
              variant: "success",
            });

            console.log(response.razorpay_payment_id);
            console.log(response.razorpay_order_id);
            console.log(response.razorpay_signature);

            const orderId = data.id; // Storing data.id in a variable
            console.log(orderId);

            // Prepare data to send to backend
            const postData = {
              key1: orderId,
              key2: response.razorpay_payment_id,
            };

            console.log(postData);

            // Send data to backend URL
            axios
              .post(
                "http://98.70.57.5/ECommercePortal/Razor/Pay/Data",
                postData
              )
              .then((response) => {
                console.log("Data sent to backend:", response.data);
              })
              .catch((error) => {
                console.error("Error sending data to backend:", error);
              });

            navigate("/myorder");
          },
          prefill: {
            name: contactDetails.firstname + " " + contactDetails.lastname,
            email: localStorage.getItem("email"),
            contact: contactDetails.phone,
          },
          theme: {
            color: "#F37254",
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          enqueueSnackbar(`${response.error.code}`, {
            variant: "error",
          });
          enqueueSnackbar(`${response.error.description}`, {
            variant: "error",
          });
          enqueueSnackbar(`${response.error.source}`, {
            variant: "error",
          });
          enqueueSnackbar(`${response.error.step}`, {
            variant: "error",
          });
          enqueueSnackbar(`${response.error.reason}`, {
            variant: "error",
          });
          enqueueSnackbar(`${response.error.metadata.order_id}`, {
            variant: "error",
          });
          enqueueSnackbar(`${response.error.metadata.payment_id}`, {
            variant: "error",
          });
        });

        rzp1.open();
      } catch (error) {
        console.error("Error creating Razorpay order:", error);
      }
    } else {
      // For cash on delivery or other payment methods
      handleFormSubmission();
    }
  };

  const handleFormSubmission = async (userEmail) => {
    try {
      const formData = {
        first_name: contactDetails.firstname,
        last_name: contactDetails.lastname,
        mobile_number: contactDetails.phone,
        address: addressDetails.address,
        pincode: addressDetails.pincode,
        city: addressDetails.city,
        state: addressDetails.state,
        payment_mode:
          paymentMethod === "cod" ? "Delivery Payment" : "Online Payment",
        cart_status: fromBuyNow ? "True" : "False",
      };

      const response = await axios.post(
        `${baseUrl}MyCart/Checkout/Save/${
          userEmail || localStorage.getItem("email")
        }`,
        formData
      );

      console.log("Response from backend:", response.data);
      navigate("/myorder");
      enqueueSnackbar(`Order Placed`, {
        variant: "success",
      });
    } catch (error) {
      console.error("Error submitting form data:", error);
      // Handle error
    }
  };

  return (
    <>
      <div className="billing">
        <h1>Select Delivery Address</h1>
        <div className="billing_box">
          <form onSubmit={handleSubmit} className="order_details">
            <div className="address_form">
              {/* Contact Details */}
              <div className="contact_details">
                <h2>Contact Details:</h2>
                <div className="input_group">
                  <div className="inp_name">
                    <input
                      type="text"
                      name="firstname"
                      value={contactDetails.firstname}
                      onChange={handleContactChange}
                      placeholder="First Name"
                      required
                    />
                    <input
                      type="text"
                      name="lastname"
                      value={contactDetails.lastname}
                      onChange={handleContactChange}
                      placeholder="Last Name"
                      required
                    />
                  </div>
                  <input
                    type="tel"
                    name="phone"
                    value={contactDetails.phone}
                    onChange={handleContactChange}
                    placeholder="Phone No."
                    required
                  />
                </div>
              </div>
              {/* Address Details */}
              <div className="address_details">
                <h2>Address Details:</h2>
                <div className="input_group">
                  <input
                    type="text"
                    name="pincode"
                    value={addressDetails.pincode}
                    onChange={handlePincodeChange}
                    placeholder="Pincode"
                    required
                  />
                  <textarea
                    type="text"
                    name="address"
                    value={addressDetails.address}
                    onChange={handleAddressChange}
                    placeholder="House No., Building, Street, Area..."
                    required
                  />
                  <input
                    type="text"
                    name="city"
                    value={addressDetails.city}
                    onChange={handleAddressChange}
                    placeholder="City"
                    required
                  />
                  <input
                    type="text"
                    name="state"
                    value={addressDetails.state}
                    onChange={handleAddressChange}
                    placeholder="State"
                    required
                  />
                </div>
              </div>
            </div>
            {/* Cart Checkout */}
            <div className="bill_cart_checkout">
              <div className="bill_cart_checkoutBox">
                <h3 className="bill_cart_checkout_head">CART TOTALS</h3>
                {cartItems.length > 0 ? (
                  <>
                    <div className="bill_cart_items">
                      {cartItems.map((item, index) => (
                        <div key={index} className="bill_cart_item">
                          <p>
                            {item.product.name} -{" "}
                            <span className="gram">
                              {item.producttype.product_type_details}
                            </span>
                            {` `}
                            <span>{item.quantity}x</span>
                          </p>
                          <p>
                            <FaRupeeSign />
                            {item.price}
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="bill_cart_checkout_total">
                      <p>Total</p>
                      <h3>
                        <b>
                          <FaRupeeSign />
                          {cartItems.reduce((acc, item) => acc + item.price, 0)}
                        </b>
                      </h3>
                    </div>
                    {/* Payment Method */}
                    <div className="payment_method">
                      <h4>Payment Method:</h4>
                      <div className="payment_options">
                        <label>
                          <input
                            type="radio"
                            name="paymentMethod"
                            value="cod"
                            checked={paymentMethod === "cod"}
                            onChange={handlePaymentMethodChange}
                            required
                          />
                          Cash on Delivery (Cash / UPI)
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="paymentMethod"
                            value="online"
                            checked={paymentMethod === "online"}
                            onChange={handlePaymentMethodChange}
                            required
                          />
                          Online Payment
                        </label>
                      </div>
                    </div>

                    <div className="payment_buttons">
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="backBtn"
                      >
                        Go Back
                      </button>
                      <button type="submit" className="proceed_checkout_button">
                        Continue <FaArrowRightLong />
                      </button>
                    </div>
                  </>
                ) : (
                  <p>No items in the cart.</p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BillingPage;
