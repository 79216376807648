import React from "react";
import "../Style/OurStory.css";
import {
  brandpg,
  story1,
  story2,
  story3,
  videobg,
} from "../assets/assetsIndex";

export default function OurStory() {

  return (
    <div className="story-main">
      <div className="video-box" >
        <video src={videobg} autoPlay loop muted />
        <h1 className="letter">Our Story</h1>
      </div>
      <h1 className="c1">How It Started</h1>
      <div className="content">
        <div className="inner-content">
          <p className="color">2010</p>
          <h1 className="heading">Started At Home</h1>
          <hr />
          <p className="texts">
            The process of making the pickles was first started at home by
            Suchita Pathak at very small scale. She loved making pickles and
            spices at home. She also loved sharing this pickles and spices with
            neibours and her loved once.The test of thos pickles and spices got
            appreciated. Over a time she found that thos spices and pickles made
            by her are really getting a good respond.Later on she started
            getting orders for delivering spices and pickles.
          </p>
        </div>
        <img className="story1" src={story1} alt="" />
      </div>
      <div className="content">
        <img className="story2" src={story2} alt="" />
        <div className="inner-content">
          <p className="color">Few Years Later</p>
          <h1 className="heading">Started With The Team</h1>
          <hr />
          <p className="texts">
            As she started getting orders, it was not possible to manage and
            deliver all the orders by her own. So she thought to join more such
            needy women who has a desire to work for Spices and Pickles. So she
            trained them for making Making Pickles and Spices. As She got extra
            hands to work with she started receiving more and more orders.
          </p>
        </div>
      </div>
      <div className="content">
        <div className="inner-content">
          <h1 className="heading">Started As A Household Business</h1>
          <hr />
          <p className="texts">
            The small work became a household business a{" "}
            <b>Shree Lakshmi Spices & Pickles</b> with the enthusiastic group of
            working women and lot of orders. The comapny started exploring by
            pruducing the pickles and spices in various flavours and categories
          </p>
        </div>
        <img className="story3" src={story3} alt="" />
      </div>
      <div className="content">
        <img className="brandlogo" src={brandpg} alt="" />
        <div className="inner-content">
          <p className="color">Janury2021</p>
          <h1 className="heading">
            Started Registred LLP Business As Savy & Suchy Spices
          </h1>
          <hr />
          <p className="texts">
            As the growth keeps increasing we decided to registered our comany
            as a brand Savy & Suchy and add more value to our purpose of
            fulfiling the test of our clients. This is how Savy & suchy have
            grown so far by the the support and love of our customers and a
            greate work of our working team.
          </p>
        </div>
      </div>
    </div>
  );
}
