import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaRupeeSign } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { bolbImgUrl, baseUrl } from "../utils";
import { loader } from "../assets/assetsIndex";
import "../Style/ShopNow.css";
import "../Style/AllCarts.css";
import {useSnackbar} from "notistack";

const AllCarts = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        // Check if user email is stored in localStorage
        const userEmail = localStorage.getItem("email");
        if (!userEmail) {
          enqueueSnackbar("Please sign in.", {
            variant: "error",
          });
          navigate("/login");
          return;
        }
        const apiUrl = `${baseUrl}MyCart/All/Data/${userEmail}`;
        const response = await axios.get(apiUrl);
        setCartItems(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };

    fetchCartData();
  }, [navigate]);

  const userEmail = localStorage.getItem("email");
  const fetchCartData = async () => {
    try {
      // if (!userEmail) {
      //   console.error("Please sign in.");
      //   return;
      // }
      const apiUrl = `${baseUrl}MyCart/All/Data/${userEmail}`;
      const response = await axios.get(apiUrl);
      setCartItems(response.data);
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const incrementQuantity = async (itemId) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === itemId ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedCartItems);
    const itemToUpdate = updatedCartItems.find((item) => item.id === itemId);
    if (itemToUpdate) {
      await axios.post(
        `${baseUrl}MyCart/Add/Data/${localStorage.getItem(
          "email"
        )}`,
        { id: itemId, quantity: itemToUpdate.quantity }
      );
      fetchCartData(); // Refetch data after successful update
    }
  };

  const decrementQuantity = async (itemId) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === itemId && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setCartItems(updatedCartItems);
    const itemToUpdate = updatedCartItems.find((item) => item.id === itemId);
    if (itemToUpdate) {
      await axios.post(
        `${baseUrl}MyCart/Add/Data/${localStorage.getItem(
          "email"
        )}`,
        { id: itemId, quantity: itemToUpdate.quantity }
      );
      fetchCartData(); // Refetch data after successful update
    }
  };

  const deleteItem = async (itemId) => {
    try {
      await axios.post(
        `${baseUrl}MyCart/Delete/Data/${localStorage.getItem(
          "email"
        )}`,
        { id: itemId }
      );
      enqueueSnackbar("Product Deleted", {
        variant: "success",
      });
      fetchCartData(); // Refetch data after successful deletion
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <div className="carts_container">
      <h1>All Carts</h1>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh",
          }}
        >
          <img
            src={loader}
            width={150}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            alt="Loading....."
          />
        </div>
      ) : cartItems.length === 0 ? (
        <div className="shopnow">
          <p>No products added</p>
          <Link
            onClick={() => {
              navigate("/");
            }}
          >
            Shop Now
          </Link>
        </div>
      ) : (
        <div className="all_carts">
          <div className="cart_table">
            <table className="mycart">
              <thead>
                <tr>
                  <th>IMAGE</th>
                  <th>PRODUCT</th>
                  <th>PRICE</th>
                  <th>QUANTITY</th>
                  <th>SUBTOTAL</th>
                  <th>
                    <AiFillDelete />
                  </th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <Link to={`/product/${item.product.id}`}>
                        <img
                          src={`${bolbImgUrl}${item.producttype.image}`}
                          alt={item.product.name}
                          className="product_cart_img"
                        />
                      </Link>
                    </td>
                    <td>
                      {item.product.name} <br />
                      <span className="gram">
                        {item.producttype.product_type_details}
                      </span>
                    </td>
                    <td>
                      Rs. <span>{item.producttype.price}</span>
                    </td>
                    <td>
                      <div className="table_quantity">
                        <div className="inc_dec">
                          <button
                            className="inc"
                            onClick={() => decrementQuantity(item.id)}
                          >
                            -
                          </button>
                          <span className="">{item.quantity}</span>
                          <button
                            className="dec"
                            onClick={() => incrementQuantity(item.id)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="subTotal_price">
                      <b>
                        Rs.<span>{item.price}</span>
                      </b>
                    </td>
                    <td>
                      <RiDeleteBin6Fill
                        className="cart_delete"
                        onClick={() => deleteItem(item.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="cart_checkout">
            <div className="cart_checkoutBox">
              <h3 className="cart_checkout_head">CART TOTALS</h3>
              <div className="cart_checkout_total">
                <p>Total</p>
                <h3>
                  <b>
                    <FaRupeeSign />
                    {cartItems.reduce((acc, item) => acc + item.price, 0)}
                  </b>
                </h3>
              </div>
              <Link to="/checkout" className="proceed_checkout">
                Proceed to checkout <FaArrowRightLong />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllCarts;
