import React from "react";
import "../Style/Header.css";
import { CiMail } from "react-icons/ci";
import { HiOutlinePhone } from "react-icons/hi2";
import { Link } from "react-router-dom";

const Header = () => {
  const emailAddress = " Psuchita085@Gmail.Com";
  const contactNumber = "+91 9890284333";

  return (
    <div className="header">
      <div className="header-box">
        <p>Welcome to our online store!</p>
        <div className="header-left">
          <p>
            <CiMail />
            {` `}
            <Link to={`mailto:${emailAddress}`} className="mailLink">
              {" "}
              {emailAddress}
            </Link>
          </p>
          <p>
            <HiOutlinePhone />
            {` `}
            <Link to={`tel:${contactNumber}`} className="phoneLink">
              {contactNumber}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
