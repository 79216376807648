import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Style/Cards.css";
import { Link } from "react-router-dom";
import { IoBag } from "react-icons/io5";
import { loader } from "../assets/assetsIndex";
import { baseUrl, bolbImgUrl } from "../utils";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";
import { PiShoppingCart } from "react-icons/pi";
import { useSnackbar } from "notistack";

function Cards() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null); // State to hold the selected product for modal
  const [selectedGramIndices, setSelectedGramIndices] = useState(
    Array(products.length).fill(0)
  );
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}Product/All/Data/1/`);

        const modifiedProduct = response.data.map((prod) => {
          return {
            ...prod,
            displayProduct: prod.producttype[0],
            quantities: prod.producttype.reduce((acc, type) => {
              acc[type.id] = 0; // Initialize quantity as 0
              return acc;
            }, {}),
          };
        });

        setProducts(modifiedProduct);
      } catch (error) {
        setError(error.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const buyNowCart = async (product, productTypeId) => {
    const userEmail = localStorage.getItem("email");
    if (!userEmail) {
      enqueueSnackbar("Please sign in first to buy this item.", {
        variant: "error",
      });
      navigate("/login");
      return;
    }

    const quantity = product.quantities[productTypeId] || 1;
    const availableQuantity = product.displayProduct.stock || 0; // Consider the available quantity from API response

    if (quantity > availableQuantity) {
      enqueueSnackbar(
        `Sorry, the maximum available quantity for this product is ${availableQuantity}.`,
        { variant: "error" }
      );
      return;
    }

    const data = {
      id: productTypeId,
      quantity: quantity,
    };
    console.log(data);

    try {
      const response = await axios.post(
        `${baseUrl}Quick/Buy/Product/${userEmail}`,
        data
      );

      if (response.status === 200) {
        enqueueSnackbar("Product bought successfully!", { variant: "success" });
        const responseData = response.data; // Store the response data
        navigate("/checkout", {
          state: { fromBuyNow: true, buyNowData: responseData },
        });
      } else {
        console.error("Error buying product:", response.statusText);
        enqueueSnackbar(`Failed to buy product. Please try again later`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error buying product:", error);
      enqueueSnackbar(`Network error. Please try again later.`, {
        variant: "error",
      });
    }
  };

  const handleAddToCart = (product, productTypeId) => {
    const userEmail = localStorage.getItem("email");
    if (!userEmail) {
      navigate("/login");
      enqueueSnackbar("Please sign in first to buy this item.", {
        variant: "error",
      });
      return;
    }

    const quantity = product.quantities[productTypeId] || 1;
    const availableQuantity = product.displayProduct.stock || 0; // Consider the available quantity from API response

    if (quantity > availableQuantity) {
      enqueueSnackbar(
        `Sorry, the maximum available quantity for this product is ${availableQuantity}.`,
        { variant: "error" }
      );
      return;
    }

    const data = {
      id: productTypeId,
      quantity: quantity,
    };

    console.log(data);

    const apiUrl = `${baseUrl}MyCart/Add/Data/${userEmail}`;

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          enqueueSnackbar(`Product added to cart successfully!`, {
            variant: "success",
          });
        } else {
          console.error("Error adding product to cart:", response.statusText);
          enqueueSnackbar(
            `Failed to add product to cart. Please try again later.`,
            { variant: "error" }
          );
        }
      })
      .catch((error) => {
        console.error("Error adding product to cart:", error);
        enqueueSnackbar(`Network error. Please try again later.`, {
          variant: "error",
        });
      });
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  const incrementQuantity = (productIndex) => {
    setProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const product = updatedProducts[productIndex];
      const productTypeId = product.displayProduct.id;
      const newQuantity = (product.quantities[productTypeId] || 0) + 1;
      updatedProducts[productIndex] = {
        ...product,
        quantities: {
          ...product.quantities,
          [productTypeId]: newQuantity,
        },
      };
      return updatedProducts;
    });
  };

  const decrementQuantity = (productIndex) => {
    setProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const product = updatedProducts[productIndex];
      const productTypeId = product.displayProduct.id;
      if (product.quantities[productTypeId] > 1) {
        const newQuantity = product.quantities[productTypeId] - 1;
        updatedProducts[productIndex] = {
          ...product,
          quantities: {
            ...product.quantities,
            [productTypeId]: newQuantity,
          },
        };
      }
      return updatedProducts;
    });
  };

  const handleProductChange = (productIndex, productTypeIndex) => {
    setSelectedGramIndices((prevIndices) => {
      const updatedSelectedGramIndices = [...prevIndices];
      updatedSelectedGramIndices[productIndex] = productTypeIndex;
      return updatedSelectedGramIndices;
    });

    setProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const product = updatedProducts[productIndex];
      const selectedType = product.producttype[productTypeIndex];
      updatedProducts[productIndex] = {
        ...product,
        displayProduct: selectedType,
        quantities: {
          ...product.quantities,
          [selectedType.id]: 1,
        },
      };
      return updatedProducts;
    });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "40vh",
        }}
      >
        <img
          src={loader}
          width={150}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          alt="Loading....."
        />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      {products.map((product, index) => {
        return (
          <div className="image-container" key={index}>
            <Link to={`/product/${product.id}`}>
              <img
                src={`${bolbImgUrl}${product.displayProduct.image}`}
                alt={product.name}
              />
            </Link>
            <div className="productname">
              <h4 className="img-name">
                <b>{product.name}</b>
              </h4>
              {product.displayProduct.stock === "Stock Out" ? (
                <p className="card_productstock">0</p>
              ) : (
                <p className="card_productstock">
                  {product.displayProduct.stock}
                </p>
              )}
            </div>
            <div className="productWeights">
              {product.producttype.map((type, typeIndex) => {
                const isSelected =
                  selectedGramIndices[index] === typeIndex ||
                  (typeIndex === 0 && product.displayProduct.id === type.id);
                return (
                  <button
                    onClick={() => handleProductChange(index, typeIndex)}
                    key={type.id}
                    className={`${isSelected ? "selected" : ""}`}
                  >
                    {type.product_type_details}{" "}
                  </button>
                );
              })}
            </div>
            <div className="product_price_quantity">
              <p className="productPrice">
                <FaRupeeSign />
                {product.displayProduct.price.toString()}
              </p>
              <div className="inc_dec">
                <button
                  className="inc"
                  onClick={() => decrementQuantity(index)}
                >
                  -
                </button>
                <span>
                  {product.quantities[product.displayProduct.id] || 1}
                </span>
                <button
                  className="dec"
                  onClick={() => incrementQuantity(index)}
                >
                  +
                </button>
              </div>
            </div>
            <div className="productBtn">
              <div className="productBtn_left">
                {product.displayProduct.stock === "Stock Out" ? (
                  <button className="buyBtn" disabled>
                    Stock Out
                  </button>
                ) : (
                  <button
                    className="buyBtn"
                    onClick={() =>
                      buyNowCart(product, product.displayProduct.id)
                    }
                  >
                    Buy Now <IoBag />
                  </button>
                )}
              </div>
              {product.displayProduct.stock !== "Stock Out" && (
                <PiShoppingCart
                  className="product_addBtn"
                  onClick={() =>
                    handleAddToCart(product, product.displayProduct.id)
                  }
                />
              )}

              {/* <FaRegEye
                  className="eyeicon"
                  onClick={() => openModal(product)}
                /> */}
            </div>
          </div>
        );
      })}

      {/* Render modal only when a product is selected */}
      {selectedProduct && (
        <Modal product={selectedProduct} closeModal={closeModal} />
      )}
    </>
  );
}

export default Cards;
