import React from "react";
import {
  brandpg,
  chili,
  chillii,
  spicee,
  spicess,
  topimg,
} from "../assets/assetsIndex";
import "../Style/Aboutus.css";

export function AboutUs() {
  return (
    <div className="about-main">
      <img src={topimg} alt="" className="about-banner" />
      <div className="about-box">
        <h2 className="title">
          <span>About Us</span>
        </h2>
        <div className="about-text">
          <div className="para-content">
            <p className="text">
              Enjoy The Greatest Test Of Our <b>Spices & Pickles!</b>
            </p>
            <p className="text2">
              The company started as small business a decade ago and now getting
              a greate response by the customers. We produce the Indian Pickles
              and Spices with various flavoures like Mango Pickle, Lemmon Pickle
              Chilli Pickle, Lemmon Jam, Tea & Milk Spices, Veg & Non-veg Dishes
              spices and many more.The aim is to deliver this awsome flavours to
              the maximum people and add-on some delicious test to their meal.
            </p>
          </div>
          <img className="brandimg" src={brandpg} alt="" />
        </div>
      </div>
      <div className="icons">
        <img src={chili} alt="" />
        <img src={spicee} alt="" />
        <img src={spicess} alt="" />
        <img src={chillii} alt="" />
      </div>
    </div>
  );
}

export default AboutUs;
